.container{
    height: 100vh;
    background-color: #F5F5F5;
    width: 360px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header{
    width: 100%;
    height: 85px;
    background-color: #1c1d3e;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.header div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.header div p{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
}

.navegador{
    height: 100%;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.layoutDivResp{
    height: 10px;
}

.navegador nav{
    height: 100%;
    width: 50%;
    margin-right: 70px;
}

.navegador ul{
    height: 70%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly
}

.navegador li{
    display: inline;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 1.1rem;
    color: #1c1d3e;
    cursor: pointer;
    transition: scale .3s;
}

.navegador li p{
    margin: 0;
    margin-bottom: 5px;
    transition: scale .3s;
}

.separador{
    background-color: #1c1d3e;
    width: 100%;
    height: 3px;
}

.underline{
    background-color: #4d92cf;
    width: 120%;
    margin-left: -10%;
    height: 3px;
}

@media screen and (max-width: 600px) {
    .container{
        height: 100vh;
        background-color: #F5F5F5;
        width: 360px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .header{
        width: 100%;
        height: 50px;
        background-color: #1c1d3e;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
}

@media screen and (max-width: 415px) {
    .container{
        width: 270px;
    }
}