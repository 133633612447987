.infoCompras{
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: -15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.infoComprasItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.infoComprasItem p{
    font-family: 'Barlow';
    font-weight: 500;
    color: #282828;
    font-size: 1.1rem;
}

.infoComprasDivisor{
    height: 86px;
    width: 3px;
    background-color: #4d92cf;
    margin-left: 20px;
    margin-right: 20px;
}

.shippingContainer {
    display: none
}

@media screen and (max-width: 900px) {
    .infoCompras{
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: -15px;
        gap: 1rem;
    }
    .infoComprasItem p{
        font-family: 'Barlow';
        font-weight: 500;
        color: #282828;
        font-size: 0.9rem;
    }
    .infoComprasDivisor{
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 768px) {
    .infoCompras{
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: -15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        display: none;
    }
    
    .infoComprasItem{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .infoComprasItem p{
        font-family: 'Barlow';
        font-weight: 500;
        color: #282828;
        font-size: 1.1rem;
    }
    
    .infoComprasDivisor{
        height: 3px;
        width: 86px;
        background-color: #1c1d3e;
        margin-left: 20px;
        margin-right: 20px;
    }

    .shippingContainer {
        display: block;
        width: 100%;
        background-color: #1c1d3e;
    }
    .shippingSwiper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        font-family: 'Barlow';
        font-size: 12px;
        line-height: 14.4px;
        font-weight: 500;
        color: #ffffff;
    }
}