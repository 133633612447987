.sectionTitleContainer{
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 80px;
    margin-top: 100px;
    width: 100%;
}

.sectionTitleContainerLibros{
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 80px;
    margin-top: 60px;
    width: 100%;
}

.sectionTitleWrapper{
    background-color: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
}

.sectionTitleWrapper h2{
    font-family: 'Cinzel';
    font-size: 2rem;
    color: #1c1d3e ;
    margin: 0;
    font-weight: 400;
}

.sectionTitleUnderline{
    width: 100%;
    height: 8px;
    background-color: #1c1d3e ;
    margin-top: 18px;
    position: absolute;
    z-index: -1;
}

@media (max-width: 768px) {
    .sectionTitleContainer{
        margin-top: 20px;
        margin-bottom: 10px;
        position: relative;
    }
    .sectionTitleContainerLibros{
        margin-top: 20px;
        margin-bottom: 40px;
        position: relative;
    }
    .sectionTitleWrapper{
        height: 25px;
    }
    .sectionTitleWrapper h2{
        font-size: 0.94rem;
    }
    .sectionTitleUnderline{
        height: 3px;
        margin-top: 10px;
    }
}