.container{
    width: 100%;
}
.container h3{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.direcciones{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.btnsContainer{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.btnBackContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.btnBack{
    cursor: pointer;
    font-family: 'Barlow';
}

.btnSubmit,
.btnSubmitDisabled{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1rem;
    border: none;
    height: 45px;
    width: 172px;
    background-color: #1c1d3e;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.btnSubmitDisabled{
    background-color: #949CA9;
}

@media (max-width: 1024px) {
    .container h3{
        font-size: 1.125rem;
    }
}

@media (max-width: 768px) {
    .container h3{
        margin: 0 0 30px 5px;
    }
}