.container{
    width: 100vw;
    display: flex;
    flex-direction: row;
}

.column{
    width: 25%;
    background-color: #F5F5F5;
}

.columnHeader{
    padding: 30px;
    padding-left: 15%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.VolArrib{
    position: sticky;
    top: 5%;
    padding-left: 0;
    z-index: 10;
    height: fit-content;
}

.VolArrib ul{
    padding: 0;
}

.VolArrib li{
    list-style-type: none;
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.columnHeader p{
    color: #222222;
    font-family: 'Barlow';
    font-size: 1.2rem;
    margin-right: 10px;
}

.column ul{
    list-style-type: none;
    padding: 0;
    padding-left: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.column li p{
    cursor: pointer;
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 1.3rem;
    font-weight: 500;
    margin: 0;
}

.underline{
    background-color:#1c1d3e;
    width: 40px;
    height: 3px;
}

.marcaDeAguaWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    margin-bottom: 200px;
}

.marcaDeAguaWrapper img{
    width: 50%;
    opacity: .2;
}

.content{
    width: 75%;
    display: flex;
    flex-direction: column;
}

.informacionContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10%;
    padding: 60px 40px 60px 40px;
}

.informacionContainer img{
    height: 470px;
    margin-left: -8%;
    filter: drop-shadow(0px 3.75px 3.75px rgba(0, 0, 0, 0.25)) drop-shadow(0px 3.75px 3.75px rgba(0, 0, 0, 0.25)) drop-shadow(0px 3.75px 3.75px rgba(0, 0, 0, 0.25));
}

.data{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.data h2, .data h3, .data p{
    color: #282828;
    font-family: 'Barlow';
    margin: 0;
}

.data h2{
    font-size: 2.8rem;
    font-weight: 400;
}

.autor{
    font-size: 2rem;
    font-style: italic;
    font-weight: 400;
}

.precio{
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
}

.precioDescuento{
    text-decoration: line-through;
    font-weight: 400;
}

.dataExtraContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dataExtraContainer p{
    font-weight: 500;
    font-size: 1.1rem;
    font-weight: 500;
}

.cantidadContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cantidadContainer p{
    color: #282828;
    font-family: 'Barlow';
    margin: 0;
    font-weight: 500;
    font-size: 1.2rem;
}

.unidadesContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.unidadesContainer input{
    width: 30px;
    height: 30px;
    outline: none;
    border: 1px solid #000000;
    border-radius: 3px;
    text-align:center;
    font-size: 1rem;
    font-family: 'Barlow';
    font-weight: 500;
    box-sizing: border-box;
}

.unidadesContainer input::-webkit-outer-spin-button,
.unidadesContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.unidadesContainer input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.btnsContainer{
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.infoCompras{
    padding-top: 100px;
    padding-bottom: 150px;
}

.disabledButton {
    color: #727070;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
    border: none;
    height: 50px;
    width: 192px;
    background-color: #b4afb0;
    border-radius: 6px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}

@media (max-width: 1800px) {
    .VolArrib{
        top: 10%;
    }
}

@media (max-width: 1300px) {
    .VolArrib{
        top: 20%;
    }
}

@media (max-width: 1200px) {
    .VolArrib{
        top: 25%;
    }
}

@media (max-width: 950px) {
    .columnHeader p{
        font-size: 1rem;
    }
    .column li p{
        font-size: 1.1rem;
    }
    .informacionContainer{
        flex-direction: column;
        gap: 0;
        padding: 20px 20px 0 20px;
    }
}

@media (max-width: 850px) {
    .VolArrib{
        top: 20%;
    }
}

@media (max-width: 768px) {
    .column {
        display: none;
    }
    .content{
        width: 100%;
    }
    .informacionContainer{
        padding: 0 20px 0 20px;
    }
    .informacionContainer img{
        height: 300px;
        object-fit: cover;
        margin-left: 0;
        margin-bottom: 15px;
    }
    .titleMobile p {
        font-size: 22px;
        font-weight: 400;
        font-family: "Barlow";
        text-align: center;
    }
    .titleMobile p span {
        font-size: 16px;
        text-align: center;
    }
    .data{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .descriptionContainer {
        max-height: 100px;
        overflow: hidden;
        margin-top: 15px;
    }
    .descriptionContainerExtended {
        margin-top: 15px;
    }
    .readMore {
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 100%;
        text-align: right;
    }
    .readLess {
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: 100%;
        text-align: left;
        margin-left: 20px;
    }
    .readMore p, .readLess p{
        color: #1c1d3e;
        text-decoration: underline;
        font-weight: 600;
        font-size: 14px;
        font-family: "Barlow";
    }
    .precio{
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }
    .dataExtraContainer{
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .unidadesContainer{
        gap: 8px;
        margin-bottom: 10px;
    }
    .btnsContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
    }
    .infoCompras{
        display: none;
    }
    .collectionContainer {
        padding-bottom: 30px;
    }
    .disabledButton{
        height: 50px;
        width: 100%;
        max-width: 400px;
    } 
}   