.container{
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.container h2{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.5rem;
}

.container p{
    margin: 0;
    color: #1c1d3e;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: -15px;
}

.container select{
    border: 1px solid #C4C4C4;
    height: 34px;
    outline: none;
    text-indent: 5px;
    font-family: 'Barlow';
    font-size: 1.1rem;
    border-radius: 6px;
    width: 100%;
}

.container button{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
    border: none;
    height: 50px;
    width: 100%;
    background-color: #4d92cf;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}
