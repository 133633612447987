.container{
    height: 100vh;
    background-color: #F5F5F5;
    width: 360px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header{
    width: 100%;
    height: 85px;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.header span {
    padding-right: 40px;
    font-weight: 600;
    font-family: 'Barlow';
    color: #1c1d3e;
    cursor: pointer;
}

.navegador{
    height: 100%;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.title_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 55%;
}

.title_container p {
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 28px;
    line-height: 0;
    color: #1c1d3e;
}

.title_underline {
    width: 55px;
    height: 2px;
    margin-left: 6px;
    background-color: #4d92cf;
}

.separador{
    background-color: #000000;
    width: 100%;
    height: 1px;
}

.underline{
    background-color: #4d92cf;
    width: 120%;
    margin-left: -10%;
    height: 3px;
}

.footerFiltros {
    margin-top: 30px;
    padding-bottom: 20px;
}

.footerFiltros div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerFiltros div img {
    width: 94px;
    height: auto;
}

.footerFiltros div p {
    text-align: center;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 13px;
    color: #000000;
}

@media screen and (max-width: 768px) {
    .container{
        height: 100vh;
        background-color: #F5F5F5;
        width: 360px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .header{
        width: 100%;
        height: 30px;
        background-color: #F5F5F5;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
    .header svg {
        margin-right: 40px;
    }
}