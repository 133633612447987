.envioContainer{
    width: 100%;
    border: 1px solid #C4C4C4;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 12px;
}

.envioHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.envioHeader div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.envioHeader p{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.1rem;
    color: #282828;
}

.envioHeader input{
    background-color: #D9D9D9;
    width: 22px;
    height: 22px;
    border-radius: 5px;
}

.direccionContainer{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: .9rem;
    color: #222222;
}

.direccionContainer ul{
    margin: 0;
}

.direccion{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: .9rem;
    color: #222222;
    cursor: pointer;
}

.verDirecciones{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: .9rem;
    color: #1c1d3e;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .envioHeader p{
        margin: 0;
        font-family: 'Barlow';
        font-weight: 700;
        font-size: 12px;
        color: #282828;
    }
    .envioHeader input{
        background-color: #D9D9D9;
        width: 12px;
        height: 12px;
        border-radius: 5px;
    }
    .direccion{
        font-size: 10px;
    }
}