.container{
    width: 100vw;
}

.buscador{
    height: 70px;
    background-color: #1c1d3e;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.layoutDiv{
    width: 300px;
}

.layoutDivResp{
    display: none;
}

.inputWrapper, .inputWrapperMobile{
    position: relative;
    margin-left: 19%;
    width: 25%;
    height: 55%;
    border-radius: 2px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
}

.inputWrapper input, .inputWrapperMobile input{
    border: none;
    outline: none;
    font-size: 12px;
    font-family: 'Roboto';
    width: 100%;
}

.inputWrapper li, .inputWrapperMobile li {
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.inputWrapper li.active, .inputWrapperMobile li.active {
    background-color: #f2f2f2;
}

.inputWrapper ul, .inputWrapperMobile ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border-top: none;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 99;
}

.inputWrapper li span, .inputWrapperMobile li span {
    font-size: 12px;
    color: grey;
    padding-left: 10px;
}

.buscadorBtns{
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    margin-right: 0.5%;
    margin-left: 2rem;
}

.buscadorBtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.buscadorBtn p{
    color: #ffffff;
    font-size: 18px;
    white-space: nowrap;
}

.cartBubble{
    margin-top: -30px;
    margin-left: 16px;
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cartBubble p{
    color: #1c1d3e;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: .8rem;
}

.navegador{
    height: 90px;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.navegador-onScroll {
    position: fixed;
    top: 0;
    width: 100%;
    transition: 0.5s;
}

.navegador img{
    height: 75%;
    width: 100%;
    max-width: 183.72px;
    margin-left: 3.5%;
    cursor: pointer;
}

.navegador nav{
    width: 50%;
    margin-right: 3.5%;
}

.navegador ul{
    list-style-type: none;
    display: flex;
    justify-content: space-between;
}

.navegador li{
    display: inline;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 16px;
    color: #1c1d3e;
    cursor: pointer;
}

.navegador li p{
    margin: 0;
    margin-bottom: 5px;
    transition: scale .1s;
}

.underline{
    background-color: #4d92cf;
    width: 100%;
    margin-left: 1%;
    height: 3px;
}

.iconosResp{
    display: none;
}
.menure{
    display: none;
}

@media screen and (max-width: 1160px) {
    .navegador nav{
        width: 60%;
    }
}

@media screen and (max-width: 1000px) {
    .navegador li{
        margin-left: 8px;
        margin-right: 8px;
        font-size: 14px;
    }
}

@media screen and (max-width: 850px) {
    .inputWrapper input {
        width: fit-content;
    }
    .navegador nav{
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    .buscador{
        height: 40px;
        background-color: #4F000B;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .containerInputMobile {
        padding: 0 32px 0 20px;
    }
    .inputWrapperMobile{
        display: flex;
        align-items: center;
        border: 1px solid #4d92cf;
        width: 100%;
        height: 30px;
        margin: 0 0 10px 0;
        color: grey;
    }
    .inputWrapperMobile ul {
        border: 1px solid #cccccc;
        border-top: 1px solid #4d92cf;
    }
    .inputWrapper li span, .inputWrapperMobile li span {
        color:rgba(60, 60, 67, 0.6);
    }
    .menure.isActive{
        display:flex ;
    }
    
    .buscadorBtns{
        display: none;
    }
    
    .buscadorBtn{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }

    .navegador{
        height: 60px;
        background-color: #F5F5F5;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        z-index: 100;
    }
    
    .navegador img{
        height: 65%;
        width: 100%;
        max-width: 106px;
        margin-left: 15px;
        cursor: pointer;
    }

    .layoutDivResp{
        display: none
    }
    .layoutDiv{
        display: none
    }

    .navegador nav{
        display: none;
    }

    .iconosResp{
        display: flex;
        flex-direction: row;
        gap: 25px;
        margin-right: 20px;
        align-items: center;
    }
}