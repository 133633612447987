.container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container h2{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.option{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    cursor: pointer;

    border: 2px solid #726d6d60;
}

.option p,
.precio{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1rem;
}

.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.precio{
    font-size: 1.1rem !important;
}

.option:nth-child(2){
    border-radius: 5px 5px 0 0;
}

.btnSubmit,
.btnSubmitDisabled{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1rem;
    border: none;
    height: 45px;
    width: 172px;
    background-color: #1c1d3e;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);

    align-self: flex-end;
}

.btnSubmitDisabled{
    background-color: #949CA9;
}

.modalLoading p {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modalLoading p {
    margin: 0;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
}

.modalContainer{
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.modalLoadingContainer{
    height: fit-content;
    width: fit-content;
    display: flex;
    text-align: center;
    outline: none;

    position: absolute; 
    left: 0;
    right: 0;
    top: calc(50% - 200px);
    margin-left: auto; 
    margin-right: auto; 
    width: 300px;
}

.btnsContainer{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.btnBackContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.btnBack{
    cursor: pointer;
    font-family: 'Barlow';
    margin: 0;
}

@media (max-width: 1024px) {
    .container h2{
        font-size: 1.125rem;
    }
    .option p,
    .precio{
        font-size: 0.75rem!important;
    }
}