.form{
    width: 100%;
}

.formModal{
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
}

.formModal p{
    margin: 0;
}

.form h3{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.rows{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.inputContainer label{
    font-family: 'Barlow';
    font-weight: 500;
    margin-bottom: 5px;
}

.inputContainer input{
    border: 1px solid #C4C4C4;
    height: 30px;
    outline: none;
    text-indent: 5px;
    font-family: 'Barlow';
}

.form div:nth-child(5) input{
    height: 60px;
}

.direccionTitle{
    font-family: 'Barlow';
    font-weight: 500;
    color: #4F000B;
    font-size: 1.2rem;
}

.inputContainer2{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.inputContainer2 input{
    border: 1px solid #C4C4C4;
    height: 30px;
    outline: none;
    text-indent: 5px;
    font-family: 'Barlow';
    border-radius: 6px;
    min-width: 50px;
}

.inputContainer2 select{
    border: 1px solid #C4C4C4;
    height: 34px;
    outline: none;
    text-indent: 5px;
    font-family: 'Barlow';
    border-radius: 6px;
}

.btnsContainer,
.btnsContainerModal{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.btnsContainerModal{
    justify-content: center;
}

.btnBackContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

}

.btnBack{
    cursor: pointer;
    font-family: 'Barlow';
}

.btnSubmit,
.btnSubmitDisabled{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1rem;
    border: none;
    height: 45px;
    width: 172px;
    background-color: #1c1d3e;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.btnSubmitDisabled{
    background-color: #949CA9;
    position: relative;
}

.formContainer{
    max-width: 1200px;
    box-sizing: border-box;
    width: 80%;
    padding: 60px;
    margin: 20px;
    margin-top: 20px;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25);
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
}

.container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    min-height: 80vh;
}

.checkboxContainer {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: "Barlow";
    font-weight: 500;
}

.checkboxContainer input {
    cursor: pointer;
}

.inputFileContainer {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 4px;
    font-size: 14px;
    font-family: "Barlow";
    font-weight: 500;
}

.inputFileContainer label, .inputFileContainer input {
    cursor: pointer;
}

.inputFileContainer span {
    font-weight: 400;
}

@media (max-width: 650px) {
    .rows{
        flex-direction: column;
    }
    .btnsContainer{
        justify-content: center;
    }
    .btnSubmit,
    .btnSubmitDisabled{
        color: #ffffff;
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 1rem;
        border: none;
        height: 45px;
        width: 100%;
        max-width: 400px;
        background-color: #1c1d3e;
        border-radius: 6px;
        cursor: pointer;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
    }
}

@media (max-width: 450px) {
    .checkboxContainer span {
        text-align: center;
        font-size: 12px;
    }
    .inputFileContainer input {
        font-size: 10px;
    }
}