.container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    min-height: 80vh;
}

.stepperContainer{
    max-width: 1200px;
    width: 80%;
    background-color: #F5F5F5;
    padding: 20px;
    margin: 20px;
    box-sizing: border-box;
}

.formContainer{
    max-width: 1200px;
    box-sizing: border-box;
    width: 80%;
    padding: 60px;
    margin: 20px;
    margin-top: 0;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25);
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 100px;
}

.resumenContainer{
    background-color: #F5F5F5;
    width: 360px;
    height: 100%;
}

.modalContainer{
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.modalTransferencia{
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
    
.modalTransferencia h3{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.5rem;

}

.modalDesc{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1rem;
}

.callToActionWrapper{
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.modalLoading p {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modalLoading p {
    margin: 0;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
}

@media (max-width: 1200px) {
    .formContainer{
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .stepperContainer{
        width: 100%;
        background-color: #FFFFFF;
        padding: 6px 20px 30px 20px;
        margin: 0px;
    }
    .modalContainer{
        max-width: 350px;
        margin: 0 auto;
    }
    .formContainer{
        width: 100%;
        padding: 0 20px 0 20px;
        box-shadow: unset;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }
    .resumenContainer{
        background-color: #F5F5F5;
        width: 360px;
        height: 100%;
    }
}