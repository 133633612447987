html {
    scroll-behavior: smooth;
}
body {
    overflow-x: visible;
}
.container{
    width: 100vw;
    display: flex;
    flex-direction: row;
}

.column{
    width: 25%;
    background-color: rgb(255, 255, 255);
}

.columnHeader{
    padding: 30px;
    padding-left: 15%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.columnHeader p{
    color: #222222;
    font-family: 'Barlow';
    font-size: 1.2rem;
    margin-right: 10px;
}

.bubblesContainer{
    padding: 10px;
    padding-left: 15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.bubblesCleanButton {
    text-decoration: underline;
    color: #1c1d3e;
    cursor: pointer;
    padding: 0 10px 15px 10px;
}

.bubble{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #EAEAEA;
    border-radius: 10px;
    width: fit-content;
    padding: 8px 10px 8px 10px;
    margin: 5px;
}

.bubble p{
    margin: 0;
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 1.1rem;
    font-weight: 500;
}

.selectContainer{
    margin-bottom: 40px;
}

.selectContainer p{
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0;
}

.selectContainer select{
    width: 70%;
    color: #7e7e7e;
    font-family: 'Barlow';
    font-size: 1.1rem;
    font-weight: 500;
    padding: 5px;
    border-radius: 7px;
    margin-left: 10px;
    margin-top: 5px;
    outline: none;
}

.columnContent{
    padding: 20px;
    padding-left: 15%;
}

.column h3{
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    text-decoration: underline;
    text-decoration-color: #1c1d3e;
    text-underline-offset: 7px;
}

.ofertas{
    margin-bottom: 40px;
    cursor: pointer;
}

.category ul{
    list-style-type: none;
    padding: 10px;
}

.category li{
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 3px;
    text-transform: lowercase;
}

.category li::first-letter {
    text-transform: uppercase;
}

.inputWrapper{
    position: relative;
    height: 38px;
    width: 70%;
    border-radius: 2px;
    background-color: #EAEAEA;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.inputWrapper input{
    background-color: #EAEAEA;
    border: none;
    outline: none;
    font-family: 'Roboto';
    width: inherit;
}

.inputWrapper li {
    padding: 10px;
    cursor: pointer;
    position: relative;
    z-index: 100000;
}

.inputWrapper li.active {
    background-color: #f2f2f2;
}

.inputWrapper ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-top: none;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
}

.mostrarMas{
    text-decoration: underline;
    text-decoration-color: #1c1d3e;
    text-underline-offset: 7px;
}

.VolArrib{
    position: sticky;
    top: 50%;
    padding-left: 15%;
    z-index: 10;
}

.VolArrib ul{
    padding: 0;
}

.VolArrib li{
    list-style-type: none;
    margin-top: 7rem;
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.precioContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.precioContainer input{
    background-color: #EAEAEA;
    border: none;
    outline: none;
    font-family: 'Roboto';
    height: 38px;
    width: 50%;
    border-radius: 6px;
    text-indent: 15px;
}

.precioContainer input::-webkit-outer-spin-button,
.precioContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.precioContainer input[type=number] {
    -moz-appearance: textfield;
}

.precioContainer button{
    border: none;
    background-color: #EAEAEA;
    min-width: 40px;
    min-height: 40px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.marcaDeAguaWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    margin-bottom: 200px;
}

.marcaDeAguaWrapper img{
    width: 50%;
    opacity: .2;
}

.content{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.cards{
    width: 100%;
    margin-top: -40px;
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 30px;
    justify-items: center;
    align-items: center;
    margin-bottom: 100px;
}

/* Calculamos el total de los demás componentes para restarlos del total de la pantalla y así 
mantener el footer abajo cuando no hay libros */
.loadingContainer {
    height: calc(100vh - 364.39px); 
}

.loading{
    text-align: center;
    font-family: 'Barlow';
    color: #000000;
    font-weight: 500;
    font-size: 1.3rem;
}

.mobileFilterButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

@media (max-width: 1550px) {
    .cards{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1500px) {
    .VolArrib{
        top: 20%;
    }
}

@media (max-width: 1300px) {
    .VolArrib{
        top: 30%;
    }
}

@media (max-width: 1085px) {
    .precioContainer{
        flex-direction: column;
        align-items: flex-start;
    }
    .VolArrib{
        top: 35%;
    }
}

@media (max-width: 1050px) {
    .cards{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 900px) {
    .cards{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .VolArrib{
        top: 20%;
    }
}

@media (max-width: 768px) {
    .columnHeader{
        display: none!important;
    }
    .cards{
        grid-template-columns: 1fr 1fr;
    }
    .content{
        width: 100%;
    }
    .bubblesContainer{
        padding: 0 2% 0 2%;
        margin-top: 10px;
        flex-direction: row;
    }
    .bubble{
        flex-direction: row;
        gap: 10px;
        background-color: #FFFFFF;
        border-radius: 9999px;
        border: 1px solid #4d92cf;
    }
    
    .bubble p{
        font-weight: 400;
        font-size: 12px;
        color: #4d92cf;
    }
}

@media (max-width: 550px) {
    .cards{
        grid-template-columns: 1fr 1fr;
    }
}