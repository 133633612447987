.modalCartContainer{
    height: fit-content;
    width: fit-content;
    top: 50%;
    left: calc(100% - 180px);
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    outline: none;
}

.modalFilterContainer{
    height: fit-content;
    width: fit-content;
    top: 50%;
    right: calc(100% - 180px);
    left: 0;
    bottom: auto;
    margin-left: 0;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    outline: none;
}

.ReactModalPortal .ReactModal__Overlay {
    transform: translateX(360px);
    transition: all .3s ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    transform: translateX(0px);
}

@media (max-width: 630px) {
    .modalFilterContainer {
        margin-left: 35px;
    }
}

@media (max-width: 530px) {
    .modalFilterContainer {
        margin-left: 55px;
    }
}

@media (max-width: 460px) {
    .modalFilterContainer {
        margin-left: 80px;
    }
}

@media (max-width: 410px) {
    .modalFilterContainer {
        margin-left: 90px;
    }
}