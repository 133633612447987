.swiper {
    width: 100%;
    height: auto;
}

.swiperSlide {
    text-align: center;
    height: 100%;
}

.swiperSlide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}