.container{
    width: 192px;
    height: 400px;
    text-align: start;
}

.gap{
    height: 50px;
}

.container img{
    object-fit: cover;
    width: 151px;
    height: 218px;
    position: absolute;
    margin-left: 20px;
    cursor: pointer;
}

.data{
    background-color: #F5F5F5;
    height: 135px;
    padding-top: 165px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.data p{
    margin: 0;
    text-align: center;
    font-family: 'Barlow';

}

.titulo{
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem;
}

.autor{
    font-weight: 500;
    font-size: 12px;
    font-style: italic;
    font-size: 1rem;
}

.precio{
    font-weight: 600;
    font-size: 1rem;
}

.nuevoPrecio {
    font-size: 1rem;
    color: #006400;
    font-weight: bold;
}

.precioDescuento{
    margin-top: 4px!important;
    text-decoration: line-through !important;
    font-size: 0.625rem;
}

@media (max-width: 768px) {
    .container{
        position: relative;
    }
}

@media (max-width: 413px) {
    .container{
        width: 170px;
    }
    .container img{
        width: 150px;
        height: 218px;
        margin-left: 10px;
    }
    .container button {
        width: 170px;
        font-size: 16px;
    }
    .titulo{
        font-size: 0.8rem;
    }
}