.container{
    width: 100vw;
    height: fit-content;
}

.recomendadosContainer {
    padding-left: 5%;
    padding-right: 5%;
}

.recomendadosContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    gap: 2rem;
}

.recomendadosPortada{
    height: 400px;
    width: 100%;
    margin-top: -15px;
}

.santeriaContainer{
    padding-left: 5%;
    padding-right: 5%;
}

.santeriaContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 70px;
}

.santerias{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
}

.santeria{
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    transition: transform .5s ease;
}

.santeria:hover{
    transform: translate(0, -15px);
}

.santeria div{
    background-color: #4F000B;
    width: 295px;
    height: 224px;
    position: absolute;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #4F000B;
}

.santeria div p{
    color: #ffffff;
    font-size: 1.5rem;
    font-family: 'Lato';
    font-weight: 500;
    margin: 0;
    margin-top: 15px;
}

.santeria img{
    width: 194px;
    aspect-ratio: 1;
    object-fit: cover;
    position: relative;
    margin-top: 75px;
    margin-left: 50px;
}

.santeria:nth-child(2) div{
    background-color: #C7ADAE;
    border-color: #7C595F;
}

.santeria:nth-child(2) div p{
    color: #4F000B;
}

.santeria:nth-child(3) div{
    background-color: #7C595F;
    border-color: #7C595F;
}

.santeria:nth-child(3) div p{
    color: #ffffff;
}

.novedadesContainer{
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 2rem;
}

.ofertasContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    gap: 2rem;
}

.cuadernosContainer{
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;

    margin-top: 80px;

    height: fit-content;
    width: 100%;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8%;
}

.cuadernosContainer img{
    height: 329px;
    width: 528px;
    object-fit: cover;
}

.cuadernosData{
    width: 40%;
    max-width: 950px;
    height: 329px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.cuadernosData h3{
    font-family: 'Cinzel';
    font-weight: 400;
    font-size: 2rem;
    color: #2D362F;
    margin: 0;
}

.cuadernosData p{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
    color: #2D362F;
}

.cuadernosBtns{
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.categoriasContainer{
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
}

.categoriasContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 70px;
}

.categorias{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
}

.categoria{
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    transition: transform .5s ease;
    margin-bottom: 50px;
}

.categoria:hover{
    transform: translate(0, -15px);
}

.categoria div{
    background-color: #4d92cf;
    color: #ffffff;
    width: 210px;
    height: 224px;
    position: absolute;
    border-radius: 5px;
    text-align: center;
    border: none;
}

.categoria div p{
    color: #ffffff;
    font-size: 1.5rem;
    font-family: 'Lato';
    font-weight: 500;
    margin: 0;
    margin-top: 15px;
    text-transform: lowercase;
}

.categoria div p:first-letter {
    text-transform: uppercase;
}


.categoria img{
    width: 150px;
    height: 221px;
    object-fit: cover;
    position: relative;
    margin-top: 60px;
    margin-left: 29px;
}

.categoriaShowMore {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.proximamente{
    color: #1c1d3e;
    font-size: 1.5rem;
    font-family: 'Barlow';
    font-weight: 500;
    margin: 0;
    margin-top: 15px;
}

@media (max-width: 1000px) {
    .recomendadosContainer {
        padding-left: 10%;
        padding-right: 10%;
    }
    .novedadesContainer{
        padding-left: 10%;
        padding-right: 10%;
    }
}


@media (max-width: 768px) {
    .recomendadosContainer {
        padding: 0;
    }
    .recomendadosContent{
        flex-direction: column;
        padding: 0 25px 0 25px;
        margin-bottom: 30px;
    }
    .ofertasContent{
        padding-left: 0;
        padding-right: 0;
    }
    .recomendadosPortada{
        height: auto;
        max-height: 400px;
        width: 100%;
        cursor: pointer;
        margin-top: -15px;
        object-fit: cover;
    }
    .novedadesContainer{
        padding: 0;
    }
    .santeriaContainer{
        padding: 0;
    }
    .categoriasContainer{
        padding: 0;
    }
    .categorias{
        padding: 0;
        display: grid;
        place-items: center;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
    }
    .categoria{
        padding: 0;
    }
}

@media (max-width: 530px) {
    .categoria div{
        width: 100%;
        max-width: 150px;
        height: 224px;
    }
    .categoria div p{
        font-size: 1.2rem;
    }
    .categoria img{
        width: 100%;
        max-width: 150px;
        height: 221px;
        margin-top: 50px;
        margin-left: 10px;
    }
}