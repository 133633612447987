.carouselContainer {
    display: flex;
    align-items: center;
    width: 65%;
}

.carouselContainerDetail {
    display: flex;
    align-items: center;
    width: 95%;
    margin-left: 20px;
}

.swiper {
    width: 95%;
    height: fit-content;
    padding-bottom: 10px;
}

.swiperSlide{
    height: fit-content;
    width: 200px;
}

.swiper-button-prev,
.swiper-button-next {
    color: rgba(28, 27, 31, 0.5);
    display: none !important;
}

.notFound{
    text-align: center;
    font-family: 'Barlow';
    color: #000000;
    font-weight: 500;
    font-size: 1.3rem;
}

.swipperButtonsContainer {
    cursor: pointer;
}

@media (max-width: 900px) {
    .swiper {
        width: 75%;
    }
}

@media (max-width: 768px) {
    .swiper {
        padding-bottom: 50px;
        width: 90%;
    }
    .swiperSlide:nth-child(1){
        margin-left: 0;
    }   
    .carouselContainer {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .carouselContainerDetail {
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 651px) {
    .swiper {
        width: 95%;
    }
}

@media (max-width: 616px) {
    .swiper {
        width: 75%;
    }
    .carouselContainer {
        width: 97%;
    }
}

@media (max-width: 540px) {
    .swiper {
        width: 85%;
    }
    .carouselContainer, .carouselContainerDetail {
        padding-left: 3%;
    }
}
@media (max-width: 500px) {
    .swiper {
        width: 90%;
    }
    .carouselContainer, .carouselContainerDetail {
        padding-left: 2%;
    }
}

@media (max-width: 460px) {
    .swiper {
        padding-bottom: 50px;
        width: 97%;
    }
    .carouselContainer, .carouselContainerDetail {
        padding-left: 3.5%;
    }
}

@media (max-width: 460px) {
    .carouselContainer, .carouselContainerDetail {
        padding-left: 3%;
    }
}

@media (max-width: 430px) {
    .carouselContainer, .carouselContainerDetail {
        padding-left: 2.5%;
    }
}

@media (max-width: 415px) {
    .carouselContainer, .carouselContainerDetail {
        padding-left: 2.7%;
    }
}