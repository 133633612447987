.disabledButton {
    color: #727070;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
    border: none;
    height: 50px;
    width: 192px;
    background-color: #b4afb0;
    border-radius: 6px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.callToActionBtn,
.callToActionBtnOutline{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
    border: none;
    height: 50px;
    width: 192px;
    background-color: #1c1d3e;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.callToActionBtnDetail,
.callToActionBtnOutlineDetail{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
    border: none;
    height: 50px;
    width: 192px;
    background-color: #1c1d3e;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.callToActionBtnOutline{
    color: #4d92cf;
    background-color: #FFFFFF;
    border: 2px solid #4d92cf;
}

.callToActionBtnOutlineDetail{
    color: #4d92cf;
    background-color: #FFFFFF;
    border: 2px solid #4d92cf
}

.callToActionBtnOutlineFiltros{
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 0.75rem;
    border: none;
    height: 30px;
    width: 132px;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
    color: #4d92cf;
    background-color: #FFFFFF;
    border: 1px solid #4d92cf;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}

.selectContainer select{
    height: 30px;
    width: 132px;
    color: #4d92cf;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 0.75rem;
    border-radius: 6px;
    background-color: #FFFFFF;
    border: 1px solid #4d92cf;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.selectContainerCategorias {
    display: flex;
    align-items: center;
}

.selectContainerCategorias select{
    height: 30px;
    width: 195px;
    color: #4d92cf;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 0.75rem;
    border-radius: 0.5px;
    background-color: #FFFFFF;
    border: 1px solid #4d92cf;
    max-height: 30px;
    overflow: auto;
    overflow-y: scroll;
}

.selectContainerCategorias select option{
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 0.5rem;
}

.inputWrapper{
    position: relative;
    height: 30px;
    width: 195px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 0.5px;
    border: 1px solid #4d92cf;
}

.inputWrapper input{
    border: none;
    outline: none;
    font-family: 'Roboto';
    background-color: #FFFFFF;
    width: 100%;
}

.inputWrapper input::placeholder{
    color: #4d92cf;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 0.75rem;
}

.inputWrapper li {
    padding: 10px;
    cursor: pointer;
    position: relative;
    z-index: 100000;
}

.inputWrapper li.active {
    background-color: #f2f2f2;
}

.inputWrapper ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-top: none;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
}

.category {
    width: 100%;
    max-width: 195px;
}

.category h3 {
    color: #1c1d3e;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 0.75rem;
}

.precioContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.precioContainer input{
    border: none;
    outline: none;
    font-family: 'Roboto';
    height: 30px;
    width: 69.5px;
    border-radius: 3px;
    text-indent: 4px;
    background-color: #D9D9D9;
}

.precioContainer input::placeholder{
    color: #4d92cf;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 0.75rem;
}

.precioContainer input::-webkit-outer-spin-button,
.precioContainer input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.precioContainer input[type=number] {
    -moz-appearance: textfield;
}

.precioContainer button{
    border: none;
    background-color: #D9D9D9;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    border: 1px solid #4d92cf;
    border-radius: 0.5px;
    height: 30px;
    width: 195px;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 0.75rem;
    color: #4d92cf;
}

.checkboxContainer input {
    margin-right: 4px
}

.checkboxContainer label {
    margin-left: 4px;
} 

@media (max-width: 768px) {
    .callToActionBtnDetail,
    .callToActionBtnOutlineDetail{
        height: 50px;
        width: 100%;
        max-width: 400px;
    }
}   