.container{
    width: 100%;
    background-color: #1c1d3e;
    padding-top: 2.3rem;
    padding-bottom: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    font-family: 'Barlow', sans-serif;
    position: relative;
}

.containerSucursal {
    color: #fff;
    padding-left: 4%;
}

.containerSucursal h4 {
    font-size: 1.3rem;
    font-weight: 700;
}

.containerSucursal div {
    display: flex;
    align-items: center;
    gap: 6px;
}

.containerSucursal div a {
    display: flex;
    align-items: center;
    gap: 6px;
    text-decoration: none;
    color: #fff;
}

.containerSucursal p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
}

.containerSucursal img {
    cursor: pointer;
}
.containerSucursal .location {
    width: 17px;
    height: 22px;
}
.containerSucursal .phone {
    width: 20px;
    height: 20px;
}
.containerSucursal .mail {
    width: 21px;
    height: 16px;
}

.containerSocial {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;
}

.logo {
    margin-bottom: 60px;
    width: 100%;
    max-width: 200px;
    height: 68.22px;
}

.logoMobile {
    display: none;
}

.socialIcons {
    display: flex;
    gap: 40px;
}

.socialIcons img {
    width: 100%;
    max-width: 30px;
    height: 30px;
}

.socialIconsMobile {
    display: none;
}

.socialIcons img,
.socialIconsMobile img {
    cursor: pointer;
}

.containerNavigation {
    color: #fff;
    padding-right: 4%;
}

.containerNavigation h4 {
    font-size: 1.3rem;
    font-weight: 700;
}

.containerNavigation div {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 500;
    line-height: 0;
}

.containerNavigation > div > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    gap: 70px;
}

.containerNavigation > div > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 63px;
}

.containerNavigation button {
    font-size: 1rem;
    font-family: 'Barlow';
    font-weight: 500;
    color: #1c1d3e;
    background-color: #FFFFFF;
    border: 2px solid #4d92cf;
    border: none;
    height: 30px;
    width: 150px;
    border-radius: 6px;
    cursor: pointer;
    padding: 0;
}

.containerNavigation a {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
}

.mobileFooterContainer {
    display: none;
}

.goUpContainerTablet {
    display: none;
}

@media (max-width: 970px) {
    .containerNavigation {
        padding-right: 0;
    }
    .containerSucursal {
        padding-left: 0;
    }
    .container {
        flex-direction:column;
    }
    .containerSucursal {
        order: 1;
    }
    .containerNavigation {
        order: 2;
    }  
    .containerSocial {
        order: 3;
    }
    .containerNavigation {
        padding-bottom: 20px;
    }
    .goUpContainerTablet {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: absolute;
        left: 50px;
        top: 50%;
    }
    .goUpContainerTablet p {
        color: white;
        font-size: 12px;
    }
}

@media (max-width: 650px) {
    .container {
        justify-content: center;
        padding-bottom: 30px;
        padding-top: 10px;
    }
    .logo {
        display: none;
    }
    .logoMobile {
        display: block;
        height: auto;
    }
    .containerSocial {
        gap: 20px;
        width: 100%;
        position: relative;
    }
    .socialIcons {
        display: none;
    }
    .socialIconsMobile {
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .containerSucursal {
        display: none;
    }
    .containerNavigation {
        display: none;
    }
    .mobileFooterContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .mobileSocialContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .goUpContainerTablet {
        display: none
    }
    .goUpContainerMobile {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: absolute;
        left: 20px;
    }
    .goUpContainerMobile p {
        color: white;
        font-size: 10px;
    }
}