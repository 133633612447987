.container{
    height: 100vh;
    background-color: #F5F5F5;
    width: 360px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header{
    width: 100%;
    height: 85px;
    background-color: #1c1d3e;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.header div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.header div p{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
}

.cards{
    height: calc(100% - 85px - 215px);     /* alto de la pantalla - header - resumen */
    overflow-x: hidden;
    overflow-y: auto;
}

.cards::-webkit-scrollbar {
    width: .5rem !important;
}
  
.cards::-webkit-scrollbar-track {
    background: #a9a9a9; 
    border-radius: .2rem;
  }
  
.cards::-webkit-scrollbar-thumb {
    background: rgb(93, 93, 93); 
    border-radius: .2rem;
}

.resumen{
    height: 215px;
}

.totalContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px;
    border-bottom: 1px solid rgba(45, 54, 47, 0.5);
}

.totalContainer p{
    margin: 0;
    font-family: 'Barlow';
    color: #000000;
    font-weight: 500;
    font-size: 1.3rem;
}

.btnsContainer{
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.callToActionBtn,
.callToActionBtnOutline{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
    border: none;
    height: 50px;
    width: 100%;
    background-color: #1c1d3e;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.callToActionBtnOutline{
    color: #4d92cf;
    background-color: #FFFFFF;
    border: 2px solid #4d92cf
}

/* Checkout styles */

.containerCheckout{
    height: fit-content;
    max-width: 500px;
    background-color: #F5F5F5;
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardsCheckout{
    height: 470px;
    overflow-x: hidden;
    overflow-y: auto;
}

.resumenCheckout{
    height: fit-content;
    border-top: 1px solid rgba(45, 54, 47, 0.5);
}

.totalContainerCheckout{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 15px;
}

.total, .cp{
    margin: 0;
    font-family: 'Barlow';
    color: #000000;
    font-weight: 500;
    font-size: 1.2rem;
}

.cp{
    color: #00000080;
}

@media screen and (max-width: 600px) {
    .container{
        height: 100vh;
        background-color: #F5F5F5;
        width: 360px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .header{
        width: 100%;
        height: 50px;
        background-color: #1c1d3e;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .MiCartResp{
        color: #ffffff;
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 0.5rem;
    }
}

.modalWrongPrice p {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 40px;
}

.modalWrongPrice p {
    margin: 0;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1.2rem;
}

.modalWrongPrice h4 {
    font-size: 1.2rem;
    font-family: 'Barlow';
}

.modalContainer{
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
}

.modalWrongPriceContainer{
    height: fit-content;
    width: fit-content;
    display: flex;
    text-align: center;
    outline: none;

    position: absolute; 
    left: 0;
    right: 0;
    top: calc(50% - 200px);
    margin-left: auto; 
    margin-right: auto;
    width: 350px;
}

@media screen and (max-width: 415px) {
    .container{
        width: 310px;
    }
    .totalContainer p{
        margin: 0;
        font-family: 'Barlow';
        color: #000000;
        font-weight: 500;
        font-size: 1.2rem;
    }
    .totalContainer:nth-child(1){
        margin-right: 25px;
    }
    .callToActionBtn,
    .callToActionBtnOutline{
        color: #ffffff;
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 1.2rem;
        border: none;
        height: 50px;
        width: 80%;
        background-color: #1c1d3e;
        border-radius: 6px;
        cursor: pointer;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
        margin-right: 25px;
    }
}