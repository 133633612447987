.container{
    width: 100vw;
    display: flex;
    flex-direction: row;
}

.column{
    width: 25%;
    background-color: #F5F5F5;
}

.columnHeader{
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-left: 15%;
}

.breadCrumbs{
    display: flex;
    flex-direction: row;
}

.columnHeader input{
    background-color: #EAEAEA;
    border: none;
    outline: none;
    font-family: 'Roboto';
    height: 38px;
    width: 70%;
    border-radius: 6px;
    text-indent: 15px;
}

.columnHeader p{
    color: #222222;
    font-family: 'Barlow';
    font-size: 1.2rem;
    margin-right: 10px;
}

.columnContent{
    padding: 20px;
    padding-left: 15%;
}

.column h3{
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    text-decoration: underline;
    text-decoration-color: #1c1d3e;
    text-underline-offset: 7px;
}

.category ul{
    list-style-type: none;
    padding: 10px;
}

.category li{
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 3px;
}


.marcaDeAguaWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    margin-bottom: 200px;
}

.marcaDeAguaWrapper img{
    width: 50%;
    opacity: .2;
}

.content{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.sucursalContainer{
    width: 80%;
    padding: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5%;
}

.data{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.data h3{
    color: #000000;
    font-family: 'Barlow';
    font-size: 2.3rem;
    font-weight: 400;
    margin: 0;
}

.data ul{
    padding: 0;
}

.data li{
    color: #000000;
    font-family: 'Barlow';
    font-size: 1.3rem;
    font-weight: 400;
    font-style: italic;
}

.btnsContainer{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.iframe{
    width: 90%;
    max-width: 800px;
    aspect-ratio: 16/8;
    border: none;
    border-radius: .4rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.115), 0 5px 5px rgba(0, 0, 0, 0.132);
}
.sucuresp{
    display: none;
}

@media screen and (max-width: 900px) {
    .sucursalContainer{
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .sucursalContainer{
        padding: 0;
    }
    .column{
        display: none;
    }
    .content{
        width: 100%;
        height: 100%;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
    }
    .data {
        order: 1;
        margin-bottom: 40px;
        width: 100%;
    }
    .iframe{
        width: 100%;
        max-width: 800px;
        aspect-ratio: 16/8;
        border: none;
        border-radius: .4rem;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.115), 0 5px 5px rgba(0, 0, 0, 0.132);
        margin-bottom: 2rem;
        order: 3;
    }
    .sucuresp{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
}