.modalDireccionContainer{
    height: fit-content;
    width: fit-content;
    display: flex;
    outline: none;

    position: absolute; 
    left: 0;
    right: 0;
    top: calc(50% - 200px);
    margin-left: auto; 
    margin-right: auto; 
    width: 600px;
}

.modalSucursalContainer{
    height: fit-content;
    width: fit-content;
    display: flex;
    outline: none;

    position: absolute; 
    left: 0;
    right: 0;
    top: calc(50% - 200px);
    margin-left: auto; 
    margin-right: auto; 
    width: 400px;
}

.modalTransferenciaContainer{
    height: fit-content;
    width: fit-content;
    display: flex;
    outline: none;

    position: absolute; 
    left: 0;
    right: 0;
    top: calc(50% - 200px);
    margin-left: auto; 
    margin-right: auto; 
    width: 400px;
}

.modalLoadingContainer{
    height: fit-content;
    width: fit-content;
    display: flex;
    text-align: center;
    outline: none;

    position: absolute; 
    left: 0;
    right: 0;
    top: calc(50% - 200px);
    margin-left: auto; 
    margin-right: auto; 
    width: 300px;
}

@media (max-width: 768px) {
    .modalDireccionContainer{
        width: 300px;
    }
}

@media (max-width: 400px) {
    .modalTransferenciaContainer{
        width: 300px;
    }
}