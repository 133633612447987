.stepperContainer{
    max-width: 1200px;
    width: 80%;
    background-color: #F5F5F5;
    padding: 20px;
    margin: 20px;
    box-sizing: border-box;
}

.form{
    width: 100%;
}

.formModal{
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
}

.formModal p{
    margin: 0;
}

.form h3{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.rows{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.inputContainer label{
    font-family: 'Barlow';
    font-weight: 500;
    margin-bottom: 5px;
}

.inputContainer input{
    border: 1px solid #C4C4C4;
    height: 30px;
    outline: none;
    text-indent: 5px;
    font-family: 'Barlow';
}

.direccionTitle{
    font-family: 'Barlow';
    font-weight: 500;
    color: #1c1d3e;
    font-size: 1.2rem;
}

.inputContainer2{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.inputContainer2 input{
    border: 1px solid #C4C4C4;
    height: 30px;
    outline: none;
    text-indent: 5px;
    font-family: 'Barlow';
    border-radius: 6px;
    min-width: 50px;
}

.inputContainer2 select{
    border: 1px solid #C4C4C4;
    height: 34px;
    outline: none;
    text-indent: 5px;
    font-family: 'Barlow';
    border-radius: 6px;
}

.btnsContainer {
    flex-direction: column;
    width: 100%;
}

.btnsContainerModal{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.btnsContainerModal{
    justify-content: center;
}

.btnBackContainer{
    display: flex;
    flex-direction: row;
    align-items: right;
    justify-content: right;
    margin-top: 40px;
}

.btnSubmit,
.btnSubmitDisabled{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1rem;
    border: none;
    height: 45px;
    width: 172px;
    background-color: #1c1d3e;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.btnSubmitDisabled{
    background-color: #949CA9;
}

.formContainer{
    max-width: 1200px;
    box-sizing: border-box;
    width: 80%;
    padding: 60px;
    margin: 20px;
    margin-top: 20px;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25), 0px 5px 5px rgba(0, 0, 0, 0.25);
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 100px;
}

.resumenContainer{
    background-color: #F5F5F5;
    width: 360px;
    height: 100%;
}

.container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    min-height: 80vh;
}

.textFormContainer {
    flex-direction: column;
}

@media (max-width: 1200px) {
    .formContainer{
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .stepperContainer{
        width: 100%;
        background-color: #FFFFFF;
        padding: 6px 20px 30px 20px;
        margin: 0px;
    }
    .modalContainer{
        max-width: 350px;
        margin: 0 auto;
    }
    .formContainer{
        width: 100%;
        padding: 0 20px 0 20px;
        box-shadow: unset;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }
    .resumenContainer{
        background-color: #F5F5F5;
        width: 360px;
        height: 100%;
    }
}