.form{
    width: 100%;
}

.formModal{
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
}

.formModal p{
    margin: 0;
}

.form h3{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.rows{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.inputContainer label{
    font-family: 'Barlow';
    font-weight: 500;
    margin-bottom: 5px;
}

.inputContainer input{
    border: 1px solid #C4C4C4;
    border-radius: 3px;
    height: 30px;
    outline: none;
    text-indent: 5px;
    font-family: 'Barlow';
}

.direccionTitle{
    font-family: 'Barlow';
    font-weight: 500;
    color: #1c1d3e;
    font-size: 1.2rem;
}

.inputContainer2{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.inputContainer2 input{
    border: 1px solid #C4C4C4;
    height: 30px;
    outline: none;
    text-indent: 5px;
    font-family: 'Barlow';
    border-radius: 3px;
    min-width: 50px;
}

.inputContainer2 select{
    border: 1px solid #C4C4C4;
    height: 34px;
    outline: none;
    text-indent: 5px;
    font-family: 'Barlow';
    border-radius: 6px;
}

.btnsContainer,
.btnsContainerModal{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.btnsContainerModal{
    justify-content: center;
}

.btnBackContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.btnBack{
    cursor: pointer;
    font-family: 'Barlow';
}

.btnSubmit,
.btnSubmitDisabled{
    color: #ffffff;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 1rem;
    border: none;
    height: 45px;
    width: 172px;
    background-color: #1c1d3e;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25), 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.btnSubmitDisabled{
    background-color: #949CA9;
}

@media (max-width: 1024px) {
    .formContainer{
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .form h3{
        font-size: 1.125rem;
        margin-bottom: 10px;
        margin-left: 5px;
    }
    .rows {
        row-gap: 0px;
    }
    .form .rows:nth-child(2) {
        flex-direction: column;
    }
    .form .rows:nth-child(3) {
        column-gap: 30px;
    }
    .inputContainer label{
        font-weight: 700;
        font-size: 12px;
        margin-left: 2px;
        margin-bottom: 2px;
    }
    .direccionTitle{
        font-weight: 700;
        font-size: 12px;
        margin: 20px 0 10px 5px;
    }
}