.container{
    width: 100%;
    background-color: white;
    padding-top: 3rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.redes{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3.5rem;
}

.wap{
    transition: all 0.7s ease;
    cursor: pointer;
    color: black;
}

.wap:hover{
    color:#00bb2d ;
    transition: all 0.7s ease;
}

.fac{
    transition: all 0.7s ease;
    cursor: pointer;
    color: black;
}

.fac:hover{
    color:#3b5998 ;
    transition: all 0.7s ease;
}

.ing{
    transition: all 0.7s ease;
    cursor: pointer;
    color: black;
}

.ing:hover{
    color:#dd2a7b ;
    transition: all 0.7s ease;
}

.redes img{
    cursor: pointer;
}

.container p{
    margin: 0;
    color: black;
    margin-left: 2rem;
    margin-right: 2rem;
    font-family: 'Poppins';
    margin-bottom: 3rem;
}

@media screen and (max-width: 415px) {
    .container{
        gap: 2rem;
    }
    .redes{
        gap: 2rem;
    }
}