.container{
    height: 100px;
    width: 100%;
    border-bottom: 1px solid rgba(45, 54, 47, 0.5);
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.container img{
    height: 100%;
    width: 75px;
    cursor: pointer;
}

.data{
    height: 100%;
    width: 218px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.titulosContainer{
    display: flex;
    flex-direction: column;
    color: #000000;
    overflow: hidden;
}

.tituloWrapper{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.titulo{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
}

.autor{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: .9rem;
    font-style: italic;
}

.accionesContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.unidadesContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.unidadesContainer input{
    width: 30px;
    height: 30px;
    outline: none;
    border: 1px solid #000000;
    border-radius: 3px;
    text-align:center;
    font-size: 1rem;
    font-family: 'Barlow';
    font-weight: 500;
    box-sizing: border-box;
}

.unidadesContainer input::-webkit-outer-spin-button,
.unidadesContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.unidadesContainer input[type=number] {
    -moz-appearance: textfield;
}

.precio{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 600;
    font-size: 1.1rem;
}


.precioDescuento{
    margin: 0;
    font-family: 'Barlow';
    font-weight: 600;
    text-decoration: line-through !important;
    font-size: .9rem;
}

@media screen and (max-width: 415px) {
    .containerMobile{
        height: 150px;
        width: 100%;
        border-bottom: 1px solid rgba(45, 54, 47, 0.5);
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
    }
    .containerMobile img{
        height: auto;
        width: 75px;
        cursor: pointer;
    }
    .dataMobile{
        height: 100%;
        width: 218px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 6px;
    }
    .accionesContainerMobile{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        gap: 6px;
    }
}