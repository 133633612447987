.container{
    position: relative;
    height: 100vh;
    background-color: #F5F5F5;
    width: 360px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.column{
    width: 100%;
    background-color: #F5F5F5;
}

.columnHeader{
    width: 100%;
    height: 85px;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.columnHeader input{
    background-color: #EAEAEA;
    border: none;
    outline: none;
    font-family: 'Roboto';
    height: 38px;
    width: 70%;
    border-radius: 6px;
    text-indent: 15px;
}

.columnHeader p{
    color: #222222;
    font-family: 'Barlow';
    font-size: 1.2rem;
    margin-right: 10px;
}

.columnContent{
    padding: 20px;
    padding-left: 15%;
}

.column h3{
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    text-decoration: underline;
    text-decoration-color: #C7ADAE;
    text-underline-offset: 7px;
}

.category ul{
    list-style-type: none;
    padding-left: 10px;
}

.category li{
    color: #1c1d3e;
    font-family: 'Barlow';
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    width: fit-content;
    margin-bottom: 3px;
}

.footerSucursales {
    position: absolute;
    left: 23%;
    bottom: 10px;
}

.footerSucursales div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerSucursales div img {
    width: 94px;
    height: auto;
}

.footerSucursales div p {
    text-align: center;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 13px;
    color: #000000;
}

@media screen and (max-width: 768px) {
    .container{
        height: 100vh;
        background-color: #F5F5F5;
        width: 360px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .columnHeader{
        width: 100%;
        height: 30px;
        background-color: #F5F5F5;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
    .columnHeader svg {
        margin-right: 40px;
    }
}

/* Corremos el footer a la izquierda para que no se superponga con el contenido */
@media screen and (max-height: 580px) {
    .footerSucursales {
        left: 30%;
    }
}